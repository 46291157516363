import * as util from "./util.js";

import { h, Fragment, render as preactRender } from "preact";
import { useState } from "preact/hooks";

import data from "./django";
const { user } = data;

// TODO: if you're typing in the textbox and hit submit or click the button (without first clicking off of the text box), the on-change doesn't fire, so you use an old (empty) value of the text field. I should probably update on keypress instead
export function useTextInput (label, props={}) {
    let id = props.id || label.toSlug();
    const [value, setValue] = useState("");
    return {
        value: value,
        id: id,
        obj: {[id]: value},
        component: <>
            <label for={id}>{label}</label>
            <input
                type="text"
                id={id}
                name={id}
                value={value}
                onInput={changeEvent => {
                    setValue(changeEvent.target.value)
                }}
                {...props}
            />
        </>
    };
}

// Options is a Map of value, and display text
export function useSelectInput (label, options, props={}) {
    let id = props.id || label.toSlug();
    const [value, setValue] = useState(options.keys().toArray().first());

    return {
        value: value,
        id: id,
        obj: {[id]: value},
        component: <>
            <label for={id}>{label}</label>
            <select
                id={id}
                name={id}
                value={value}
                onChange={changeEvent => {
                    setValue(changeEvent.target.value);
                }}
                {...props}
            >
                {
                    // key is the React ordering key
                    options.toArray().map(([value, displayText]) => (
                        <option key={value} value={value}>
                            {displayText}
                        </option>
                    ))
                }
            </select>
        </>
    };
}

export function makeForm (onSubmit, inputs) {
    const values = Object.merge(...inputs.getEach("obj").filter(v => !!v));

    // I think, to handle errors, we need to have each `input` expose a error setter and handle displaying its own error. Still a TODO though
    const handleErrors = (errorObj) => window.alert(errorObj
        .entries()
        .flatMap(([_key, errors]) => errors)
        .join("\n"));

    const Form = (
        <form onSubmit={(evt) => onSubmit(evt, values, handleErrors)}>
            {
                inputs.map(i => i.component)
            }
            <input type="submit" value="Submit" />
        </form>
    );

    return {
        component: Form,
        values: values
    };
}

function Logo () {
    return <div id="header-bar">
        <a href="/"><div class="logo">Reruns</div></a>{" "}
        <span class="account-info">
            { user ? <>
                <span class="username">{user.username}</span>{" | "}
                <a href="/accounts/logout"><div class="logout">Log Out</div></a>
            </> : <>
                <a href="/accounts/signup/">Sign Up</a>
            </>}
        </span>
    </div>;
}

export function render (component) {
    const container = document.getElementById("content");
    while (container.hasChildNodes()) {
        container.removeChild(container.firstChild);
    }
    preactRender(<>
        <header><Logo /></header>
        <main>
            {component}
        </main>
    </>, container);
}


// Used for constructing classes in JSX
export function c (...args) {
    // Filter truthy
    return args.filter().join(" ");
}
