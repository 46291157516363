import { h, Fragment } from "preact";

import { render } from "./common.jsx";
import { Rewatch, Video } from "./models.js";

import data from "./django";

// If we're logged out, user is null, rewatches is undefined
let { user, current_videos, your_rewatches } = data;
if (user) {
    your_rewatches = your_rewatches.map(r => new Rewatch(r));
    current_videos = current_videos.map(v => new Video(v));
}

// This is the component that renders at /

function displayRewatch(r) {
    // r is a Rewatch

    const rewatch_thumbnail_url = "";

    // This component needs to show the "state" of the rewatch
    //
    return <aside>
        {/*<img src={`${r.getThumbnailUrl()}`}>*/}
        <h3>{r.title}</h3>
        <a href={`/${r.uuid}`}>See all videos</a>
    </aside>;
}

// Takes a list of videos
// Returns a list of Rewatches, where each Rewatch has been augmented with its videos
// Ordering of videos is preserved (since there's a backend query to order videos by available date)
// Uses the list of Rewatches which is in the global scope as your_rewatches
function groupVideosByRewatch (videos) {
    const current_rewatches = [];

    // Using i and j for fine-grained control over iteration.
    // We only want to hit the first video from each video
    // And we need to preserve order (both in that the rewatches need to be ordered by how early their first video shows up, and that the rest of the videos need to be ordered within a rewatch)
    for (let i = 0; i < videos.length; i++) {
        const video = videos[i];
        const rewatch = your_rewatches.find(r => r.id === video.rewatch_id);
        current_rewatches.push(rewatch);
        rewatch.videos = [video];
        // Grab all the rest of the videos for that rewatch from the rest of the list
        for (let j = i + 1; j < videos.length; j++) {
            if (videos[j].rewatch_id === rewatch.id) {
                // Then this video belongs to the same rewatch as us
                rewatch.videos.push(videos[j]);
                videos.splice(j, 1);
                j --;
            }
        }
    }

    return current_rewatches;
}

function displayVideo(rewatch) {
    {/*
    The argument is a rewatch, but we really want to focus on the fact that rewatch.videos.first() is a video that needs to be watched.

    Video thumbnail
    Video title
    Rewatch that it's from
    When it was re-released
    */}

    const video = rewatch.videos.first();

    return <aside>
        <img class="thumbnail" src={video.thumbnail_url} width="120" height="90" />
        <h3>{video.title}</h3>
        <div>From {rewatch.title}</div>
        <a href={`/${rewatch.uuid}`}><em>Watch!</em></a>
    </aside>
}

function Index() {
    if (user) {
        return <>
            <section>
                <header>
                    <h1>Welcome back, {user.username.toTitleCase()}</h1>
                </header>
                    <a href="/new"><strong>Start a Rerun!</strong></a>
            </section>

            <hr />

            {/* Big sections, the thumbnail of the oldest video from:
                each rewatch that is active where you haven't watch every released video */}
            <section>
                <header>
                    <h1>Keep Watching</h1>
                </header>

                {groupVideosByRewatch(current_videos).map(displayVideo)}
            </section>

            <hr />

            <section>
                <header>
                    <h1>Your Reruns</h1>
                </header>

                {/* For scale reasons, this page can't load every video from every rewatch that you've ever participated in.
                For now, I want to load every video from the above rewatches, because I don't know how to get just the unwatched ones. (if that' easy, we can do that instead)

                But the rest of the page doesn't need information about the videos, only the name of the rewatch.
                */}

                {/*Rewatches that you've created or participated in.
                I don't know how I want to sort these, there's not really a good solution.
                We just need to communicate why it's here, if it's active, etc. */}

                {/* List of current rewatches as columns, with whether it's been */}
                {your_rewatches.map(displayRewatch)}
                {/* {user.rewatches.map(Rewatch)} */}
            </section>
        </>;
    }else {
        return (
            <main>
            <section>
                <header>
                    <h1>Reruns</h1>
                    <h2>Reruns helps you (re)watch your favorite Youtube series.</h2>
                    <p>
                        <a href="/accounts/login"><strong>Login</strong></a>{" "}
                        <a href="/accounts/signup"><em>Sign Up</em></a>
                    </p>
                </header>
            </section>
            <section>
                <aside>
                    <h3>Keep track of progress</h3>
                    <p>The Youtube Algorithm can't tell where you are in a re-watch if you've already watched the series once.</p>
                    <p>Its recommendations are terribly biased towards newer content.</p>
                    <p>Reruns keeps track of what videos you've rewatched, and which still need to be <em>re</em>-experienced.</p>
                </aside>
                <aside style="color: rgba(0, 0, 0, 0.8)">
                    <h3>Send you reminders</h3>
                    <mark style="background: var(--bs-yellow)">Not Yet Implemented</mark>
                    <p>
                        Rewind time, and rewatch a series with the same pacing as the first time.
                    </p>
                    <p>
                        Get a reminder when your videos are <em>re</em>-released, through the platform that suits you best:
                        <ul>
                            <li>Email</li>
                            <li>Discord</li>
                            <li>RSS</li>
                        </ul>
                    </p>
                </aside>
                <aside>
                    <h3>Import playlists from Youtube</h3>
                    <p>
                        After signing up, starting a rerun is plug-and-play. Just put in:
                        <ul>
                            <li>A link to a Youtube playlist with the videos</li>
                            <li>The time you want to wait in between videos</li>
                        </ul>
                    </p>
                </aside>
            </section>
            </main>
        );
    }
};

render(<Index />);
