import data from "./django";
const user = data.user;

class Rewatch {
    constructor (raw_rewatch) {
        // The raw rewatch looks something like:
        // {
        //     "model": "rewatch.rewatch",
        //     "pk": 1,
        //     "fields": {
        //         "uuid": "7bf03bea-ec44-47cb-b3a3-7cbc2de816ec",
        //         "user": 8,
        //         "created_date": "2022-10-22T15:35:40.719Z",
        //         "last_updated": "2022-10-22T15:35:40.719Z",
        //         "is_loading": true,
        //         "is_template": false,
        //         "original_template": null,
        //         "title": "",
        //         "pace": "",
        //         "source_id": "",
        //         "count_rewatched": 0,
        //         "is_active": false,
        //         "start_date": null,
        //         "participating_users": []
        //     }
        // }

        this.id = raw_rewatch.pk;
        for (const [field, value] of Object.entries(raw_rewatch.fields)) {
            // TODO: here and below convert field to camelCase
            this[field] = value;
        }
        this.videos = null;
    }

    static PACE_OPTIONS = new Map([
        ["DAILY", "Daily"],
        ["HALF_DAILY", "Every other day"],
        ["WEEKLY", "Weekly"],
        ["MONTHLY", "Monthly"],
        // ["CUSTOM", "Custom"], // We do not have the UI for this at this point
        ["SOURCE", "Original release pacing"],
        ["SELF_GUIDED", "All available now"],
    ]);

    getPaceDisplay () {
        return Rewatch.PACE_OPTIONS.get(this.pace);
    }
}

class Video {
    constructor (raw_video) {
        // Raw video looks like
        // {
        //     "id": 45,
        //     "source_id": "meSIp50ymkU",
        //     "title": "Minecraft - FoolCraft 3 #6: Blue Fire Best Fire",
        //     "is_available": true,
        //     "available_date": null,
        //     "original_release_date": "2018-07-14T19:49:20Z",
        //     "users_who_have_watched": [],
        //     "rewatch_id": 33
        // }


        this.id = raw_video.pk;
        for (const [field, value] of Object.entries(raw_video.fields)) {
            this[field] = value;
        }
        this.rewatch_id = this.rewatch;
        delete this.rewatch;

        this.available_date = new Date(this.available_date);

        // TODO: Do this on the server side!
        this.is_watched = user ? this.users_who_have_watched.includes(user.id) : false;
        console.log(this.is_watched);
        delete this.users_who_have_watched;
    }
}

export { Video, Rewatch };
